export const sliderVideos = {
    init: function () {
        if (this.params.slidesPerView === 1) {
            resetVideos(this.slides)
            playVideo(this, this.slides, this.realIndex)
        }
    },
    activeIndexChange: function () {
        if (this.params.slidesPerView === 1) {
            resetVideos(this.slides)
            playVideo(this, this.slides, this.realIndex)
        }
    }
}

const resetVideos = (slides) => {
    slides.forEach( (slide) => {
        const video =  slide.getElementsByTagName('video')

        if (video.length) {
            video[0].pause()
            video[0].currentTime = 0
        }
    });
}

const playVideo = (slider, slides, realIndex) => {
    const currenSlideVideo = slider.slides[realIndex].getElementsByTagName('video')

    if (currenSlideVideo.length) {
        slider.autoplay.stop()
        const video = currenSlideVideo[0]

        video.play()
        video.addEventListener('ended', () => {
            video.currentTime = 0
            realIndex === slides.length - 1 ? slider.slideTo(0) : slider.slideNext()
            slider.autoplay.start()
        })
    }
}
