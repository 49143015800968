const playerHover = () => {
    const teamCards = document.querySelectorAll('.team__card');

    teamCards.forEach(teamCard => {
        let bounds;
        const glowElement = teamCard.querySelector('.team__card__glow');

        const debounce = (func, wait) => {
            let timeout;
            return (...args) => {
                clearTimeout(timeout);
                timeout = setTimeout(() => func.apply(this, args), wait);
            };
        };

        const rotateToMouse = debounce((e) => {
            const mouseX = e.clientX;
            const mouseY = e.clientY;
            const leftX = mouseX - bounds.x;
            const topY = mouseY - bounds.y;
            const center = {
                x: leftX - bounds.width / 2,
                y: topY - bounds.height / 2
            }
            const distance = Math.sqrt(center.x ** 2 + center.y ** 2);

            teamCard.style.transform = `
                scale3d(1.07, 1.07, 1.07)
                rotate3d(
                    ${center.y / 100},
                    ${-center.x / 100},
                    0,
                    ${Math.log(distance) * 2}deg
                )
            `;

            teamCard.querySelector('.team__card__glow').style.backgroundImage = `
                radial-gradient(
                    circle at
                    ${center.x * 2 + bounds.width / 2}px
                    ${center.y * 2 + bounds.height / 2}px,
                    #ffffff25,
                    #0000000f
                )
            `;
        });

        teamCard.addEventListener('mouseenter', () => {
            bounds = teamCard.getBoundingClientRect();
            document.addEventListener('mousemove', rotateToMouse);
            glowElement.style.opacity = '1';
        });

        teamCard.addEventListener('mouseleave', () => {
            document.removeEventListener('mousemove', rotateToMouse);
            teamCard.style.transform = '';
            glowElement.style.opacity = '0';
        });
    });
}


document.addEventListener("DOMContentLoaded", function(event) {
    playerHover();
});