const popup = document.querySelector('.js-popup');
const popupCloseButton = document.querySelector('.js-close-popup');
const hasPopupCookie = document.cookie.split(';').some((item) => item.trim().startsWith('popup=degraafschap_popup'));

const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

if (hasPopupCookie && popup) {
    popup.remove();
} else if (popup) {
    let daysLeft = 1

    if (popup.attributes['data-days-left']) {
        daysLeft = popup.attributes['data-days-left'].value
    }

    setTimeout(() => {
        popup.classList.add('is-active');

        popupCloseButton.addEventListener('click', () => {
            popup.classList.remove('is-active');
            setCookie('popup', 'degraafschap_popup', daysLeft);
        });
    }, 1500);
}