import { sliderVideos } from "./sliderVideos";
import Swiper from 'swiper';
import { Navigation, Pagination, Keyboard, Autoplay, EffectFade } from 'swiper/modules';
import 'swiper/css';
Swiper.use([Navigation, Pagination, Keyboard, Autoplay, EffectFade]);

window.sliders = function() {
    let sliderElements = document.querySelectorAll('[data-slider]');
    let sliders = {};

    if (!sliderElements.length) {
        return;
    }

    sliderElements.forEach(slider => {
        let opts = JSON.parse(slider.dataset.slider);

        // Convert function strings to actual functions
        if (opts.on) {
            for (let event in opts.on) {
                if (typeof opts.on[event] === 'string' && opts.on[event].startsWith('function')) {
                    opts.on[event] = new Function('return ' + opts.on[event])();
                }
            }
        }

        const slides = slider.querySelectorAll('.swiper-slide');

        // Enable Swiper if only one slide is available
        if (slides.length === 1) {
            opts = { enabled: false }
        } else {
            Object.assign(opts, {
                on: {
                    ...sliderVideos,
                    ...opts.on
                }
            })
        }

        sliders[slider.getAttribute('id')] = new Swiper(slider, opts);
    });
};