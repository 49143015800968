const playerPosition = () => {
    const playerPosition = document.querySelector('[data-player-position]');

    if (playerPosition) {
        const position = playerPosition.getAttribute('data-player-position').toLowerCase();

        document.querySelectorAll('[data-position]').forEach(group => {
            if (group.getAttribute('data-position') === position) {
                group.style.opacity = '1';
            }
        });
    }
}

document.addEventListener("DOMContentLoaded", playerPosition);