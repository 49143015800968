let isMobile = window.innerWidth < 1536;
let subMenuActive = null;
let subSubMenuActive = null;

const mainMenu = document.querySelector('.js-main-menu');

const updateIsMobile = () => {
    isMobile = window.innerWidth < 1024;

    const mainMenu = document.querySelector('.main-menu');
    const backdrop = document.querySelector('.backdrop');

    if (isMobile) {
        if (mainMenu) mainMenu.classList.add('-translate-x-full');
    } else {
        if (mainMenu) mainMenu.classList.remove('-translate-x-full');
    }
};

const toggleMenu = () => {
    document.querySelector('body').classList.toggle('menu-open');
    document.querySelector('body').classList.toggle('overflow-hidden');
    document.querySelector('.main-menu').classList.toggle('max-2xl:-translate-x-full');
    document.querySelector('.main-menu').classList.toggle('max-2xl:translate-x-0');
};

const setActiveSubMenu = (id) => {
    subMenuActive = subMenuActive === id ? null : id;
    document.querySelectorAll('.menu--main > li > .submenu').forEach(submenu => {
        submenu.classList.toggle('hidden', submenu.dataset.id != subMenuActive);
        submenu.classList.toggle('2xl:hidden', submenu.dataset.id != subMenuActive);
    });
};

const setActiveSubSubMenu = (id) => {
    subSubMenuActive = subSubMenuActive === id ? null : id;
    document.querySelectorAll('.submenu .submenu').forEach(subsubmenu => {
        subsubmenu.classList.toggle('hidden', subsubmenu.dataset.id != subSubMenuActive);
        subsubmenu.classList.toggle('2xl:hidden', subsubmenu.dataset.id != subSubMenuActive);
    });
};

document.querySelectorAll('.submenu').forEach(submenu => {
    submenu.classList.add('hidden');
});

updateIsMobile();

const toggleMenuButton = document.querySelector('.menu-button--toggle');

if (toggleMenuButton) {
    toggleMenuButton.addEventListener('click', toggleMenu);
}

document.querySelectorAll('.submenu-button').forEach(button => {
    button.addEventListener('click', () => {
        setActiveSubMenu(button.dataset.id);
        // button.classList.toggle('-rotate-180');
    });
});

document.querySelectorAll('.subsubmenu-button').forEach(button => {
    button.addEventListener('click', () => {
        setActiveSubSubMenu(button.dataset.id);
        // button.classList.toggle('-rotate-180');
    });
});

window.addEventListener('resize', () => {
    updateIsMobile();
});

function addClassToBody(className) {
    document.body.classList.add(className);
}

function removeClassFromBody(className) {
    document.body.classList.remove(className);
}

let prevScrollPos = window.scrollY;

function handleScroll() {
    const currentScrollPos = window.scrollY;

    if (currentScrollPos > 0) {
        addClassToBody('header__fixed');
    } else {
        removeClassFromBody('header__fixed');
    }

    if (currentScrollPos > 250 && currentScrollPos < prevScrollPos) {
        addClassToBody('header__fixed__up');
    } else {
        removeClassFromBody('header__fixed__up');
    }

    if (currentScrollPos > 250 && currentScrollPos > prevScrollPos) {
        addClassToBody('header__fixed__down');
    } else {
        removeClassFromBody('header__fixed__down');
    }
    prevScrollPos = currentScrollPos;
}

window.addEventListener('scroll', handleScroll);

const searchToggleButtons = document.querySelectorAll('.header-search__button--toggle');

if (searchToggleButtons) {
    searchToggleButtons.forEach(button => {
        button.addEventListener('click', (e) => {
            e.preventDefault();
            document.body.classList.toggle('search-open');

            const isOpen = document.body.classList.contains('search-open');
            const input = document.querySelector('.header-search__input');

            console.log(isOpen);
            if (isOpen) {
                if (input) input.focus();
                window.addEventListener('wheel', preventScroll, { passive: false });
                window.addEventListener('touchmove', preventScroll, { passive: false });
            } else {
                window.removeEventListener('wheel', preventScroll);
                window.removeEventListener('touchmove', preventScroll);
            }
        });
    });
}

// Function to prevent scrolling
function preventScroll(event) {
    event.preventDefault();
}