window.addresses = function () {
    return {
        modelId: null,
        countryId: null,
        modelName: null,
        zipCode: '',
        appLanguage: '',
        errors: {},
        onChange(ev) {
            this.countryId = ev.target.value;
        },
        getErrors(key) {
            if (!Object.keys(this.errors).length || Object.keys(this.errors).indexOf(key) === -1) {
                return false;
            }

            return this.errors[key];
        },
        hasErrors(key) {
            let errors = this.getErrors(key);
            return errors && errors.length;
        },
        updateErrors(errors) {
            this.errors = errors;
        },
    };
};
