// Fancybox Docs: https://fancyapps.com/docs/ui/installation
import { Fancybox } from "@fancyapps/ui";

export default class fancyboxComponent {

  constructor(element, options) {
    return Fancybox.bind(element, {
      ...{
          Thumbs: false
      }, ...options,
    });
  }

}
