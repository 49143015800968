import createSpringSlider from '../components/spring-slider';

window.springSliders = function() {
    /**
     * Spring Slider element
     */
    const springSliderElements = document.querySelectorAll('[data-spring-slider]');

    if (!springSliderElements) {
        return;
    }

    springSliderElements.forEach(springSlider => {
        createSpringSlider(springSlider, {
            slidesPerView: 1,
            navigation: {
                prevEl: '.spring-slider-swiper-button-prev',
                nextEl: '.spring-slider-swiper-button-next',
            },
            pagination: {
                el: '.spring-slider-pagination',
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 2,
                },
                1600: {
                    slidesPerView: 3,
                }
            },
            on: {
                breakpoint: function (swiper) {
                    swiper.update();
                },
                snapIndexChange: function (swiper) {
                    if (swiper.isEnd) {
                        document.querySelector('.spring-slider-gradient').classList.add('!opacity-0');
                    } else {
                        document.querySelector('.spring-slider-gradient').classList.remove('!opacity-0');
                    }
                },
            },
        });
    });
};

// springSlider();
