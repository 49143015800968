const footerWidgetTitles = document.querySelectorAll('.footer-widget__title');

if (footerWidgetTitles) {
    footerWidgetTitles.forEach(footerWidgetTitle => {
        footerWidgetTitle.addEventListener('click', (e) => {
            e.preventDefault();

            const footerWidget = e.currentTarget.parentNode;

            if (footerWidget.classList.contains('is-expanded')) {
                footerWidget.classList.remove('is-expanded');
            } else {
                footerWidget.classList.add('is-expanded');
            }
        });
    });
}