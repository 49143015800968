import '/resources/scss/app.scss';
import 'vue-final-modal/style.css'

import Alpine from 'alpinejs';
import {createVfm} from 'vue-final-modal'

import collapse from '@alpinejs/collapse';
import axios from 'axios';
import {createPinia} from 'pinia';

import collect from 'collect.js';

window.axios = axios.create({
    headers: {
        'X-CSRF-Token': window.CSRF
    },
});

window.pinia = createPinia()
window.vfm = createVfm()

window.collect = collect;

window.Alpine = Alpine;
Alpine.plugin(collapse);
Alpine.start();

import './modules/addresses.js';
import './modules/header.js';
import './modules/footer.js';
import './modules/homepage.js';
import './modules/partner.js';
import './modules/notifications.js';
import './modules/sliders.js';
import './modules/springSliders.js';
import './modules/turnstile';
import './modules/playerHover.js'
import './modules/playerPosition.js'
import './modules/countdown.js'
import './modules/popup.js'
import scrollAnimations from './modules/scrollAnimations.js'
import fancyboxComponent from "./modules/fancybox";

// Run scroll animations
scrollAnimations();

// Init sliders
window.onload = (event) => {
    window.sliders();
    window.springSliders();
    // Re-run scroll animations
    scrollAnimations();
}

const components = [{
    class: fancyboxComponent, selector: "[data-fancybox]"
}];

// Check if class exist and run component
components.forEach((component) => {
    if (document.querySelector(component.selector) !== null) {
        document
            .querySelectorAll(component.selector)
            .forEach((element) => new component.class(element, component.options));
    }
});

