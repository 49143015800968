// self executing function here
(function () {
    function handleHashChange() {
        if (window.location.hash) {
            const hash = window.location.hash.substring(1);
            let targetElement = document.querySelector(`[data-fancybox="${hash}"]`);

            if (targetElement) {
                requestAnimationFrame(() => {
                    targetElement.click();
                });
            }
        }
    }

    handleHashChange();

    window.addEventListener('hashchange', function () {
        handleHashChange();
    });
})();

