const countdownEl = document.querySelector('.js-countdown');
const countdownDate = countdownEl ? countdownEl.getAttribute('data-countdown-date') : null;

if (countdownEl && countdownDate) {
    const countdown = new Date(countdownDate).getTime();
    const daysEl = document.querySelector('.js-countdown-days');
    const hoursEl = document.querySelector('.js-countdown-hours');
    const minutesEl = document.querySelector('.js-countdown-minutes');
    const secondsEl = document.querySelector('.js-countdown-seconds');
    const ticketsBtn = document.querySelector('.js-countdown-tickets-button');

    const interval = setInterval(() => {
        const now = new Date().getTime();
        const distance = countdown - now;
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (daysEl) daysEl.innerHTML = days.toString().padStart(2, '0');
        if (hoursEl) hoursEl.innerHTML = hours.toString().padStart(2, '0');
        if (minutesEl) minutesEl.innerHTML = minutes.toString().padStart(2, '0');
        if (secondsEl) secondsEl.innerHTML = seconds.toString().padStart(2, '0');

        if (distance < 0) {
            clearInterval(interval);

            if (daysEl) daysEl.innerHTML = '00';
            if (hoursEl) hoursEl.innerHTML = '00';
            if (minutesEl) minutesEl.innerHTML = '00';
            if (secondsEl) secondsEl.innerHTML = '00';
        } else {
            if (ticketsBtn) ticketsBtn.classList.remove('opacity-50');
            if (ticketsBtn) ticketsBtn.classList.remove('pointer-events-none');
        }
    }, 1000);
}